var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageDefault',{attrs:{"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"my-3"},[_c('p',{staticClass:"is-size-4",attrs:{"translate":""}},[_vm._v("El pago no se ha completado")])]),(_vm.order.status != 'error')?_c('div',[_c('p',{attrs:{"translate":""}},[_vm._v(" Puedes intentarlo de nuevo, si así lo deseas. Tu selección aún sigue activa. ")]),_c('p',{attrs:{"translate":""}},[_vm._v(" Recuerda que dispones de un tiempo limitado para completar tu compra. ")]),(_vm.order.lookup_ref)?_c('p',{staticClass:"is-flex-tablet mt-3"},[_c('router-link',{staticClass:"button is-info mr-3 my-1",attrs:{"data-cy":"link-try-again","to":{
            name: 'multipass-order-confirm',
            params: { lookup_ref: _vm.order.lookup_ref },
            query: _vm.$defaultQuery(),
          }}},[_c('translate',[_vm._v("Ir al pedido")])],1),_c('button',{staticClass:"button my-1 has-text-grey",attrs:{"data-cy":"link-try-again"},on:{"click":_vm.newOrder}},[_c('translate',[_vm._v("Cambiar selección")])],1)],1):_vm._e()]):_c('div',[_c('p',{staticClass:"mt-3",attrs:{"translate":""}},[_vm._v("No se ha realizado ningún cargo a tu tarjeta.")]),_c('p',{attrs:{"translate":""}},[_vm._v(" Si deseas intentarlo de nuevo, puedes realizar una nueva selección. ")]),_c('router-link',{staticClass:"button button-2406 is-info mt-4",attrs:{"data-cy":"link-new-order","to":{
          name: 'event',
          params: { lookup_ref: _vm.event.lookup_ref },
          query: _vm.$defaultQuery(),
        }}},[_c('translate',[_vm._v("Nuevo pedido")])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }