<template>
  <PageDefault :isLoading="isLoading">
    <template v-slot:content>
      <div class="my-3">
        <p translate class="is-size-4">El pago no se ha completado</p>
      </div>
      <div v-if="order.status != 'error'">
        <p translate>
          Puedes intentarlo de nuevo, si así lo deseas. Tu selección aún sigue activa.
        </p>
        <p translate>
          Recuerda que dispones de un tiempo limitado para completar tu compra.
        </p>
        <p class="is-flex-tablet mt-3" v-if="order.lookup_ref">
          <router-link
            class="button is-info mr-3 my-1"
            data-cy="link-try-again"
            :to="{
              name: 'multipass-order-confirm',
              params: { lookup_ref: order.lookup_ref },
              query: $defaultQuery(),
            }"
          >
            <translate>Ir al pedido</translate>
          </router-link>
          <button
            @click="newOrder"
            class="button my-1 has-text-grey"
            data-cy="link-try-again"
          >
            <translate>Cambiar selección</translate>
          </button>
        </p>
      </div>
      <div v-else>
        <p class="mt-3" translate>No se ha realizado ningún cargo a tu tarjeta.</p>
        <p translate>
          Si deseas intentarlo de nuevo, puedes realizar una nueva selección.
        </p>
        <router-link
          class="button button-2406 is-info mt-4"
          data-cy="link-new-order"
          :to="{
            name: 'event',
            params: { lookup_ref: event.lookup_ref },
            query: $defaultQuery(),
          }"
        >
          <translate>Nuevo pedido</translate>
        </router-link>
      </div>
    </template>
  </PageDefault>
</template>

<script>
import MultipassOrderMixin from "@/mixins/MultipassOrderMixin"
import Component, { mixins } from "vue-class-component"

@Component
export default class MultipassOrderError extends mixins(MultipassOrderMixin) {}
</script>
